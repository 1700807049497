import {useDomainDataStore} from "~/stores/domain";
import {useUserStore} from "~/stores/user";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('consent-module', {
        mounted (el, binding: {modifiers: { force: true}}) {
            const userStore = useUserStore()
            if ((!userStore.userData.features.consentUrlPrefix || userStore.userData.features.consentUrlPrefix === '') && !userStore.userData.features.disableOutgoingLinks) {
                return
            }

            const domainDataStore = useDomainDataStore()
            const edumediaHost = new URL(domainDataStore.getDomainHome()).hostname
            const matches = el.querySelectorAll("a")

            matches.forEach(match => {
                let url = new URL(match.href)
                let matchHost = url.hostname
                if (userStore.userData.features.consentUrlPrefix) {
                    if (edumediaHost !== matchHost) {
                        if (userStore.userData.features.disableOutgoingLinks) {
                            match.innerHTML += ` (${match.href})`
                            match.removeAttribute('href')
                        } else {
                            match.href = userStore.userData.features.consentUrlPrefix + url.href
                        }
                    }
                    else if (binding.modifiers.force) {
                        match.href = userStore.userData.features.consentUrlPrefix + url.href
                    }
                }
            })
        },
        //TODO
        // getSSRProps (binding, vnode) {
            // you can provide SSR-specific props here
            // return {}
        // }
    })
})